export enum VoltageLevel
{
    LowVoltage,
    // MediumVoltage
}

export enum VoltageUnit
{
    V,
    kV
}

// export enum PowerUnit
// {
//     W,
//     kW,
//     MW,
//     kVA
// }

export enum PowerUnit
{
    // W,
    kW = 1000,
    MW = 1000000
}

export enum POIPowertype
{
    AC,
    DC
}

export enum TransformerType {
    HVMV = 'HVMV',
    MVLV = 'MVLV'
}

export enum HasPlantOrSizingStatus {
    WithoutStatus,
    HasPlantData,
    HasSizingsData
}
export enum PlantStatus {
    WithoutStatus,
    NewPlant,
    EditPlant,
    RemovePlant,
    RefreshPlantList
}

export enum PVModuleStatus {
    WithoutStatus,
    RemovePVModule,
    DeletePVModuleHasSizings,
    UpdatePVModuleHasSizings,
    DeletePVModuleOK,
    // UpdatePVModuleOK,
    DeletePVModuleError,
    UpdatePVModuleError,
    RefreshPVModuleList
}
