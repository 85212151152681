import {createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw} from "vue-router";
import {RouterPaths} from "@/types/RouterData";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Login",
        component: () => import('../views/Login.vue')
    },
    {
        path: "/general",
        name: "General",
        component: () => import('../views/General.vue'),
        children: [
            {
                path: RouterPaths.Plants,
                name: RouterPaths.Plants,
                component: () => import('../views/Plants.vue') // done
            },
            {
                path: RouterPaths.NewPlant,
                name: RouterPaths.NewPlant,
                component: () => import('../views/NewPlant.vue') // done
            },
            {
                path: RouterPaths.Inverters,
                name: RouterPaths.Inverters,
                component: () => import('../views/Inverters.vue') // done
            },
            {
                path: RouterPaths.PVModules,
                name: RouterPaths.PVModules,
                component: () => import('../views/PVModules.vue') // done
            },
            {
                path: RouterPaths.Sizings,
                name: RouterPaths.Sizings,
                component: () => import('../views/Sizings.vue')
            },
            {
                path: RouterPaths.NewSizing,
                name: RouterPaths.NewSizing,
                component: () => import('../views/NewSizing.vue')
            },
            {
                path: RouterPaths.SizingReport,
                name: RouterPaths.SizingReport,
                component: () => import('../views/SizingReport.vue')
            },
            {
                path: RouterPaths.UserSettings,
                name: RouterPaths.UserSettings,
                component: () => import('../views/UserSettings.vue')
            },
            {
                path: RouterPaths.UserSecurity,
                name: RouterPaths.UserSecurity,
                component: () => import('../views/UserSecurity.vue')
            }
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () => import('../views/PageNotFound.vue')
    },
    // {
    //   path: "/about",
    //   name: "About",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
    // },
];

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
});

export default router;
