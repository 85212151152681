import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14673db4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "cookiebanner"
}
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showBanner)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('cookies.bannertitle')), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('cookies.bannerfirsttext')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('cookies.bannersecondtext')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('cookies.bannerthirdtext')), 1),
          _createElementVNode("a", {
            target: "blank",
            href: _ctx.cookiespolicyurl
          }, _toDisplayString(_ctx.$t('cookies.cookiespolicy')), 9, _hoisted_2),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('cookies.bannerfourtext')), 1),
          _createElementVNode("button", {
            class: "button__link",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setShowCookieSettingsDialog && _ctx.setShowCookieSettingsDialog(...args)))
          }, _toDisplayString(_ctx.$t('cookies.settings')), 1)
        ]),
        _createElementVNode("button", {
          class: "button button__general",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.acceptCookies && _ctx.acceptCookies(...args)))
        }, _toDisplayString(_ctx.$t('cookies.bannerbuttonaccept')), 1),
        _createElementVNode("button", {
          class: "button button__grey",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.denyCookies && _ctx.denyCookies(...args)))
        }, _toDisplayString(_ctx.$t('cookies.bannerbuttondeny')), 1)
      ]))
    : _createCommentVNode("", true)
}