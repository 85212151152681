<template>
  <div v-if="showBanner" class="cookiebanner">
    <div>
      <p>{{ $t('cookies.bannertitle') }}</p>
    </div>
    <div>
      <p>{{ $t('cookies.bannerfirsttext') }}</p>
      <p>{{ $t('cookies.bannersecondtext') }}</p>
      <span>{{ $t('cookies.bannerthirdtext') }}</span>
      <a target="blank" :href="cookiespolicyurl">{{ $t('cookies.cookiespolicy') }}</a><span>{{ $t('cookies.bannerfourtext') }}</span>
      <button class="button__link" @click="setShowCookieSettingsDialog">{{ $t('cookies.settings') }}</button>
    </div>
    <button class="button button__general" @click="acceptCookies">{{ $t('cookies.bannerbuttonaccept') }}</button>
    <button class="button button__grey" @click="denyCookies">{{ $t('cookies.bannerbuttondeny') }}</button>
  </div>
</template>


<script lang="ts">

import {computed, defineComponent, ref} from "vue";
import {useStore} from "vuex";
import NavigationServices from "@/services/NavigationServices";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "CookieBanner",
  setup() {

    const store = useStore();

    const {t, locale} = useI18n({useScope: 'global'});

    // console.log('document.cookie: ' + document.cookie);
    const showBanner = ref((document.cookie && !document.cookie.includes('_ga')
        && !document.cookie.includes(NavigationServices.cookieConsentName)) || !document.cookie);
    // console.log('showBanner: ' + showBanner.value);

    const denyCookies = () => {
      NavigationServices.denyCookies();
      hideCookieBanner();
    };

    const acceptCookies = () => {
      NavigationServices.acceptCookies();
      hideCookieBanner();
    }

    const hideCookieBanner = () => {
      showBanner.value = false;
    }

    const setShowCookieSettingsDialog = () => {
      store.commit("setShowCookieSettingsDialog", true);
    }

    const cookiespolicyurl = computed(() => {
      return NavigationServices.getCookiesPolicyURL(locale.value);
    });

    return {
      denyCookies,
      acceptCookies,
      setShowCookieSettingsDialog,
      showBanner,
      cookiespolicyurl
    }
  }
});
</script>

<style scoped>
.cookiebanner {
  font-size: 14px;
  color: white;
  z-index: 2;
  width: 550px;
  border-radius: 10px;
  padding: 20px 20px 20px 20px;
  background: var(--navbar-color);
  overflow: hidden;
  position: fixed;
  bottom: 30px;
  left: 30px;
}

.cookiebanner div:first-child > p {
  font-size: 1.5rem;
  color: var(--ingeteam-color);
}

.cookiebanner div {
  padding-bottom: 1rem;
}

.cookiebanner .button {
  margin-right: 0.5rem;
}

.cookiebanner a {
  color: #007ad9;
}

.button__link {
  background: none;
  border: none;
  padding: 0;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #007ad9;
  cursor: pointer;
}

</style>
