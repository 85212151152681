import {ENVIRONMENT} from "@/environments/environment";

class APIConfigService {
    getUrlToISM() {
        if (ENVIRONMENT.ENVIRONMENT === 'DEV') {
            return 'apipro';
        } else {
            return `${this.getUrlToISP()}`;
        }
    }

    getUrlToISP() {
        const env = ENVIRONMENT.ENVIRONMENT;
        let apiurl = '';
        switch (env) {
            case "DEV":
                apiurl = 'apidev';
                break;
            case "PRE":
                apiurl = 'apipre';
                break;
            case "PRO":
                apiurl = 'api';
                break;
        }
        return apiurl;
    }
}

export default new APIConfigService();
