<template>
  <Dialog :header="headermsg" v-model:visible="display" @update:visible="closeDialog" :style="{width: '35vw'}" position="left">
    <div>
      <p>{{ $t('cookies.bannerfirsttext') }}</p>
      <p>{{ $t('cookies.bannersecondtext') }}</p>
      <span>{{ $t('cookies.bannerthirdtext') }}</span>
      <a target="blank" :href="cookiespolicyurl">{{ $t('cookies.cookiespolicy') }}</a>
      <hr>
      <div class="data--aligncenter aligndata--separate5">
        <p class="cookiessettings--subtitle">{{ $t('cookies.analyticcookiestitle') }}</p>
        <SelectButton v-model="analyticscookiesenabled" :options="analyticsoptions" optionLabel="name" optionValue="value"
                      aria-labelledby="single"/>
      </div>
      <p>{{ $t('cookies.analyticcookiestext') }}</p>
    </div>
    <template #footer>
      <button @click="saveAndCloseDialog" class="p-button-text button__general" value="Cancel">{{ $t('menu.saveandclose') }}</button>
    </template>
  </Dialog>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import NavigationServices from "@/services/NavigationServices";
import {PVModuleFilter} from "@/types/SizingData";

export default defineComponent({
  name: "CookieSettingsDialog",
  setup() {

    const store = useStore();

    const {t, locale} = useI18n({useScope: 'global'});

    const headermsg = ref(t('cookies.bannertitle'));

    const analyticsoptions = ref([
      {name: `${t('general.false')}`, value: false},
      {name: `${t('general.true')}`, value: true}
    ]);
    const analyticscookiesenabled = ref(false);

    const display = computed(() => {
      return store.getters.getShowCookieSettingsDialog;
    });

    const saveAndCloseDialog = () => {
      // console.log('valor seleccionado: ' + analyticscookiesenabled.value);
      analyticscookiesenabled.value ? NavigationServices.acceptCookies() : NavigationServices.denyCookies();
      closeDialog();
    };

    const closeDialog = () => {
      store.commit("setShowCookieSettingsDialog", false);
    };

    const cookiespolicyurl = computed(() => {
      return NavigationServices.getCookiesPolicyURL(locale.value);
    });

    return {
      display,
      headermsg,
      cookiespolicyurl,
      analyticscookiesenabled,
      analyticsoptions,
      saveAndCloseDialog,
      closeDialog
    }
  }
});
</script>

<style scoped>

a {
  color: #007ad9;
}

.cookiessettings--subtitle {
  font-size: 1.5rem;
  color: var(--ingeteam-color);
}

</style>
