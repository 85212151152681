export enum SizingStatus {
    WithoutStatus,
    NewSizing,
    EditSizing,
    CloneSizing,
    RemoveSizing,
    RefreshSizingList
}

export enum PVModuleDialogStatus {
    Display,
    PVModuleSavedOK,
    PVModuleSavedError,
    Hide
}

export enum PlantInfoDialogStatus {
    Display,
    Hide
}

export enum CloneSizingStatus {
    WithoutStatus,
    OK,
    ExistSizingName,
    Error
}

export enum ComponentsName {
    PlantResume = 'PlantResume'

}

export enum PVModuleFilter {
    all = 'all',
    owner = 'owner'
}

export enum SizingValuesStatus {
    WithoutStatus,
    ResetStringsValuesStatus,
    ResetInvertersValuesStatus,
    ResetStringsValuesConfirmationStatus,
    ResetInvertersValuesConfirmationStatus
}
