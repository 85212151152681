import axios, {AxiosInstance} from "axios";
import store from "@/store";
import router from "@/router";

const apiClient: AxiosInstance = axios.create({
    headers: {
        "Content-type": "application/json"
    },
});

apiClient.interceptors.request.use(
    (config) => {
        let token = '';
        if (config.url?.includes('users/validate')) {
            token = store.getters.getISMToken;
        } else {
            if (!config.url?.includes('authenticator')) {
                token = store.getters.getUserToken;
            }
        }
        if (token.length > 0) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        router.push("/");
    }
    return Promise.reject(error);
});

export default apiClient;
