import {Store, useStore} from "vuex";
import {PlantSizingItemDTO} from "@/types/PlantUIParameters";
import {stateNavigation} from "@/store";

class GeneralServices {

    resetCompleteSizingAppState(store: Store<any>) {
        // console.log("resetCompleteSizingAppState ANTES. store.state: " + JSON.stringify(store.state));
        this.resetNavigationPlantAndSizingAppState(store);
        store.commit("resetStateUser");
        store.commit("resetStateGeneral");
        store.commit("resetAppState");
        // console.log("resetCompleteSizingAppState DESPUES. store.state: " + JSON.stringify(store.state));
    }
    resetNavigationPlantAndSizingAppState(store: Store<any>) {
        // console.log("resetNavigationPlantAndSizingAppState ANTES. store.state: " + JSON.stringify(store.state));
        store.commit("resetStateNavigation");
        store.commit("resetStatePlant");
        store.commit("resetStateSizing");
        store.commit("resetStatePVModuleSection");
        // console.log("resetNavigationPlantAndSizingAppState DESPUES. store.state: " + JSON.stringify(store.state));
    };

    resetSizingAppState(store: Store<any>) {
        // console.log("resetSizingAppState ANTES. store.state: " + JSON.stringify(store.state));
        store.commit("resetStateNavigation");
        store.commit("resetStateSizing");
        // console.log("resetSizingAppState DESPUES. store.state: " + JSON.stringify(store.state));
    };
}

export default new GeneralServices();
