<template>
  <Dropdown v-model="selectedValue" :options="dropdownvalues" optionLabel="label" optionValue="value" :filter="enableFilter"
            :class="styleClass" @change="getSelectedValue"/>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from "vue";
import {DataList} from "@/types/DataList";

export default defineComponent({
  name: "dropdownui",
  props: {
    selected: {
      type: [Number, String],
      required: false
    },
    styleClass: {
      type: String,
      required: false
    },
    values: {
      type: Array,
      required: true
    },
    enableFilter: {
      type: Boolean,
      required: false,
      default: false
    },
    // placeholder: {
    //   type: String,
    //   required: true
    // }
  },
  emits: ['value-selected'],
  setup(props, context) {

    const selectedValue = ref(props.selected);
    const placeholdertext = ref('select one');

    const dropdownvalues = computed(() => {
      return props.values as DataList[];
    });

    watch(() => props.selected, (selection, prevSelection) => {
      selectedValue.value = props.selected;
    })

    const getSelectedValue = () => {
      context.emit('value-selected', selectedValue.value);
    };

    return {
      selectedValue,
      dropdownvalues,
      placeholdertext,
      getSelectedValue
    }
  }
});
</script>

<style scoped>
/*.p-dropdown {*/
/*  width: 9.5rem;*/
/*  height: 1.75rem;*/
/*}*/

/*.p-dropdown-label {*/
/*  font-size: 0.5rem;*/
/*}*/
</style>
