<template>
  <div class="footer" :class="{ 'footer__wrapper__login': isLoginRoute, 'footer__wrapper__nologin': !isLoginRoute }">
    <div class="footer__wrapper">
      <div>
        <a href="https://www.ingeteam.com" target="_blank">Ingeteam </a>|<span> v{{ webVersion }}</span><span
          v-if="!isLoginRoute">-m{{ motorVersion }}</span>
      </div>
      <div>

      </div>
      <div class="footer__legal" v-if="isLoginRoute">
        <a target="_blank" :href="legaladviceurl">{{ $t('footer.legaladvice') }}</a>
        <a target="_blank" :href="privacypolicyurl">{{ $t('footer.privacypolicy') }}</a>
        <a target="_blank" :href="cookiespolicyurl">{{ $t('cookies.cookiespolicy') }}</a>
        <button class="button__link" @click="setShowCookieSettingsDialog">{{ $t('footer.cookiespolicy') }}</button>
      </div>
    </div>
  </div>
  <CookieBanner v-if="!hasCookies"></CookieBanner>
  <CookieSettingsDialog v-if="ShowCookieSettingsDialog"></CookieSettingsDialog>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {RouterPaths} from "@/types/RouterData";
import {useStore} from "vuex";
import CookieBanner from "@/components/General/CookieBanner.vue";
import CookieSettingsDialog from "@/components/UI/CookieSettingsDialog.vue";
import {useI18n} from "vue-i18n";
import NavigationServices from "@/services/NavigationServices";

export default defineComponent({
  name: "FooterBar",
  components: {
    CookieBanner,
    CookieSettingsDialog
  },
  setup() {
    const route = useRoute();
    const store = useStore();

    const webVersion = "2.0.0";

    const {t, locale} = useI18n({useScope: 'global'});

    const getCookies = () => {
      const cookies = document.cookie;
      // console.log('cookies: ' + JSON.stringify(cookies));
      return cookies.includes(NavigationServices.cookieConsentName);
    };

    const hasCookies = ref(getCookies());

    const motorVersion = computed(() => {
      return store.getters.getMotorVersion;
    });
    const currentRoute = computed(() => {
      return route.name;
    });

    const isLoginRoute = computed(() => {
      return currentRoute.value === RouterPaths.Login;
    });

    const legaladviceurl = computed(() => {
      return NavigationServices.getLegalAdviceURL(locale.value);
    });

    const privacypolicyurl = computed(() => {
      return NavigationServices.getPrivacyPolicyURL(locale.value);
    });

    const cookiespolicyurl = computed(() => {
      return NavigationServices.getCookiesPolicyURL(locale.value);
    });

    const setShowCookieSettingsDialog = () => {
      store.commit("setShowCookieSettingsDialog", true);
    }

    const ShowCookieSettingsDialog = computed(() => {
      return store.getters.getShowCookieSettingsDialog;
    });

    watch(ShowCookieSettingsDialog, () => {
      if (!ShowCookieSettingsDialog.value) {
        const hascookies = getCookies();
        hasCookies.value = hascookies;
      }
    });

    return {
      webVersion,
      motorVersion,
      isLoginRoute,
      legaladviceurl,
      privacypolicyurl,
      cookiespolicyurl,
      ShowCookieSettingsDialog,
      setShowCookieSettingsDialog,
      hasCookies
    }
  }
});
</script>

<style scoped>
.footer {
  /*height: var(--footer-height);*/
  color: var(--footer-text-color);
  position: fixed;
  bottom: 0;
  width: 100vw;
}

.footer__wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.footer__wrapper__login {
  background-color: white;
  opacity: 0.6;
}

.footer__wrapper__nologin {
  background-color: #ECECEC;
  opacity: 0.4;
}

.footer__legal {
  display: flex;
  justify-content: space-between;
}

.footer__legal button {
  color: inherit;
}

.footer__wrapper div {
  width: 33%;
}
</style>
