<template>
  <div @click="toggle" class="section--selected">
    <span aria-haspopup="true" aria-controls="overlay_menu">{{ user.firstName }}</span>
    <i class="pi pi-angle-down"></i>
  </div>
  <!--  <Button type="button" icon="pi pi-ellipsis-v" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu"/>-->
  <Menu ref="menu" id="overlay_menu" :model="items" :popup="true">
    <template #item="{ item, props }">
      <router-link v-slot="{ href, navigate }" :to="item.route" custom>
        <a :href="href" v-bind="props.action" @click="navigate" class="data--aligncenter aligndata--separate5">
          <span :class="item.icon"/>
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </router-link>
    </template>
  </Menu>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref, Ref} from "vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {User} from "@/types/User";

export default defineComponent({
  name: "User",
  components: {},
  setup() {
    const store = useStore();

    const {t, locale} = useI18n({useScope: 'global'});

    const menu = ref();

    const isUserValid = computed(() => {
      const user = store.getters.getUser as User;
      return user?.isUserValid;
    });

    const menuItems: any[] = [];
    menuItems.push({
      label: t('User.profile'),
      icon: 'pi pi-user',
      route: 'usersettings'
    });
    if (isUserValid.value) {
      menuItems.push({
        label: t('User.security'),
        icon: 'pi pi-shield',
        route: 'usersecurity'
      });
    }
    menuItems.push({
      label: t('general.logout'),
      icon: 'pi pi-sign-out',
      route: '/'
    });

    const items = ref([
      {
        label: t('User.settings'),
        items: menuItems
      }
    ]);

    const toggle = (event: any) => {
      menu.value.toggle(event);
    };

    const user = ref(store.getters.getUser)

    return {
      user,
      items,
      toggle,
      menu,
    }
  }
});
</script>

<style scoped>

</style>
