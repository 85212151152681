import APIDataServices from "@/services/APIDataServices";
import {Store} from "vuex";
import ResponseData from "@/types/ResponseData";
import {PlantStatus, PVModuleStatus} from "@/types/plantdata";
import {PlantSizingItemDTO} from "@/types/PlantUIParameters";
import {CloneSizingStatus, SizingStatus} from "@/types/SizingData";
import {computed} from "vue";
import {bootstrap, setOptions} from "vue-gtag";

class NavigationServices {

    cookieConsentName = 'cc_consent';

    deletePlant(store: Store<any>) {
        const plantid = store.getters.getPlantData.id;
        APIDataServices.deletePlantById(plantid)
            .then((response: ResponseData) => {
                // console.log('eliminada planta: ' + JSON.stringify(response));
                store.commit("setPlantStatus", PlantStatus.RefreshPlantList);
                store.commit("resetStatePlant");
            })
            .catch((e: Error) => {
                console.log('Se ha producido un error en el borrado de la planta: ' + JSON.stringify(e));
            });
        store.commit("setShowConfirmationDialog", false);
    }

    refreshSizingList(store: Store<any>) {
        store.commit("setSizingStatus", SizingStatus.RefreshSizingList);
    }

    cloneSizing(store: Store<any>) {
        const sizingitem: PlantSizingItemDTO = store.getters.getSelectedSizing;
        // console.log('sizingitem: ' + JSON.stringify(sizingitem));
        const plantid = sizingitem.plantId;
        const sizingid = sizingitem.id;
        APIDataServices.plantSizingLowVoltageClone(plantid, sizingid)
            .then((response: ResponseData) => {
                // console.log('respuesta clonado: ' + response.data);
                if (response.data === "OK") {
                    this.refreshAndResetSelectedSizing(store);
                } else {
                    store.commit("setCloneSizingStatus", CloneSizingStatus[response.data]);
                    this.resetSelectedSizingAndHideDialog(store);
                    store.commit('setSizingStatus', SizingStatus.WithoutStatus);
                }
            }).catch((e: Error) => {
            console.log(e);
            this.refreshAndResetSelectedSizing(store);
        });
    };

    deletePVModule(store: Store<any>) {
        const pvmoduleid = store.getters.getPVModuleId;
        const userid = store.getters.getUserId;
        // console.log('borrar panel: ' + pvmoduleid);
        APIDataServices.deletePVModule(userid, pvmoduleid)
            .then((response: ResponseData) => {
                const data = response.data;
                // console.log('deletePVModule: ' + JSON.stringify(data));
                store.commit("setPVModuleStatus",
                    data === 'OK' ? PVModuleStatus.DeletePVModuleOK
                        : data === "NoDeleteHasSizings" ? PVModuleStatus.DeletePVModuleHasSizings
                            : data === "DeleteError" ? PVModuleStatus.DeletePVModuleError : PVModuleStatus.RefreshPVModuleList);
            })
            .catch((e: Error) => {
                console.log(e);
            });
        store.commit("setShowConfirmationDialog", false);
    }

    refreshAndResetSelectedSizing(store: Store<any>) {
        this.refreshSizingList(store);
        this.resetSelectedSizingAndHideDialog(store);
    }

    resetSelectedSizingAndHideDialog(store: Store<any>) {
        store.commit("resetSelectedSizing");
        store.commit("setShowConfirmationDialog", false);
    }

    deleteSizing(store: Store<any>) {
        const sizingitem: PlantSizingItemDTO = store.getters.getSelectedSizing;
        // console.log('sizingitem to remove: ' + JSON.stringify(sizingitem));
        if (sizingitem.id) { // si ha seleccionado un dimensionado
            if (store.getters.isLowVoltageLevelPlantData) {
                APIDataServices.plantSizingLowVoltageDelete(sizingitem.plantId, sizingitem.id)
                    .then((response: ResponseData) => {
                        // console.log('resultado remove dimensionado: ' + JSON.stringify(response));
                        this.refreshAndResetSelectedSizing(store);
                    })
                    .catch((e: Error) => {
                        console.log(e);
                        this.refreshAndResetSelectedSizing(store);
                    });
            } else if (store.getters.isMediumVoltageLevelPlantData) {
                APIDataServices.plantSizingMediumVoltageDelete(sizingitem.plantId, sizingitem.id)
                    .then((response: ResponseData) => {
                        // console.log('resultado remove dimensionado: ' + JSON.stringify(response));
                        this.refreshAndResetSelectedSizing(store);
                    })
                    .catch((e: Error) => {
                        console.log(e);
                        this.refreshAndResetSelectedSizing(store);
                    });
            }
        }
    }

    getLegalAdviceURL(lang: string) {
        return `/legal/${lang}/legaladvice.html`;
    };

    getPrivacyPolicyURL(lang: string) {
        return `/legal/${lang}/privacypolicy.html`;
    };

    getCookiesPolicyURL(lang: string) {
        return `/legal/${lang}/cookiespolicy.html`;
    };

    acceptCookies() {
        console.log('se aceptan las cookies');
        // (<any>window).gtag('js', new Date());
        // (<any>window).gtag('config', 'G-ZJ6XWN1DFG');
        setOptions({
            config: {id: 'G-ZJ6XWN1DFG'}
        });

        bootstrap().then((gtag) => {
            // all done!
        });
        this.createCCConsentCookie(true);
    };

    private hasGoogleAnalyticsCookies() {
        return this.getGoogleAnalyticsCookies().length > 0;
    }

    private getGoogleAnalyticsCookies() {
        return document.cookie.split(';').filter(c => c.includes('_ga'));
    }

    denyCookies() {
        // console.log('se deniegan las cookies');
        // window['ga-disable-G-ZJ6XWN1DFG'] = true;
        // (<any>window).gtag('consent', 'default', {
        //   'ad_storage': 'denied',
        //   'analytics_storage': 'denied'
        // });


        if (this.hasGoogleAnalyticsCookies()) {
            const getGACookies = this.getGoogleAnalyticsCookies();
            const domain = '.ingeconsunplanner.com';
            getGACookies.map((GACookie: string) => {
                this.deleteCookie(GACookie, domain);
            })
        }
        this.createCCConsentCookie(false);
    };

    deleteCookie(cookiename: string, domain: string) {
        const cookie = `${cookiename}; expires=Thu, 01 Jan 1970 00:00:00 UTC;Domain=${domain};SameSite=None;Secure=false;path=/;`;
        // console.log('cookie a eliminar: ' + cookie);
        document.cookie = cookie;
    }

    addYear(date: Date, years: number) {
        date.setFullYear(date.getFullYear() + years);
        return date;
    };

    createCCConsentCookie(status: boolean) {
        const today = new Date();
        document.cookie = `${this.cookieConsentName}=${status}; expires=${this.addYear(today, 1)};SameSite=None;Secure=true`;
    };
}

export default new NavigationServices();
