export type AuthenticationResult = Readonly<{
    // username: string,
    token: string;
}>;

export type AuthenticationToken = Readonly<{
    token: string;
}>;

export interface LoginUserDTO {
    eMail: string;
    password: string;
    deviceId?: string;
}

export interface TokenResponse2FA {
    twoFactorToken: string;
    twoFactorRequired: boolean;
    twoFactorPreferredMethod: string;
    blockEndsAt: string;
    errorCode: string;
    twoFactorConfiguredMethods: string[];
}

export enum TokenResponse2FAErrorCode {
    twoFactorAuthenticationValidationRequired = 'twoFactorAuthenticationValidationRequired',
    Authenticator = 'Authenticator'
}

export interface AuthenticatorConfigBody {
    isTwoFactorEnabled: boolean;
    deviceid?: string;
}

export interface AuthenticatorConfigResponse {
    isTwoFactorEnabled: boolean;
    configuredMethods: string[];
    twoFactorToken?: string;
}

export interface AuthenticatorSetupResponse {
    secretKey: string;
    qrCode: string;
}

export interface AuthenticatorValidateResponse {
    token?: string;
    attemptsLeft?: number;
    attemptsLimit?: number;
    blockEndsAt?: string;
    errorCode?: string;
}

export enum AuthenticatorValidateResponseErrorCode {
    twoFactorAuthenticationAttemptsLimitReached = 'twoFactorAuthenticationAttemptsLimitReached',
    twoFactorAuthenticationInvalidCode = 'twoFactorAuthenticationInvalidCode'
}

export enum AuthenticatorValidationStatus {
    None,
    Info2FA,
    AuthApp,
    EnabledToSetup,
    EnabledToValidate,
    Validated
    // EnabledAndValidated
}
