import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: _ctx.selectedValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValue) = $event)),
    options: _ctx.dropdownvalues,
    optionLabel: "label",
    optionValue: "value",
    filter: _ctx.enableFilter,
    class: _normalizeClass(_ctx.styleClass),
    onChange: _ctx.getSelectedValue
  }, null, 8, ["modelValue", "options", "filter", "class", "onChange"]))
}