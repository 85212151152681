import {createApp} from "vue";

import App from "./App.vue";

import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import BlockUI from "primevue/blockui";
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import Tooltip from 'primevue/tooltip';
import SelectButton from 'primevue/selectbutton';
import Paginator from 'primevue/paginator';
import Menu from 'primevue/menu';
import Button from 'primevue/button';
import TieredMenu from 'primevue/tieredmenu';
import InputSwitch from 'primevue/inputswitch';
import InputOtp from 'primevue/inputotp';

import VueGtag from "vue-gtag";

// import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/themes/fluent-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '@/normalize.css';

import router from "./router";
import store from "./store";
import en from "./locales/en.json";
import es from "./locales/es.json";

import {createI18n, I18nOptions} from 'vue-i18n'


const datetimeFormats: I18nOptions["datetimeFormats"] = {
    'es': {
        short: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        },
        shortwhour: {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'en': {
        short: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        },
        shortwhour: {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    }
};

const numberFormats = {
    'es': {
        decimal: {
            style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    },
    'en': {
        decimal: {
            style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2
        },
        percent: {
            style: 'percent', useGrouping: false
        }
    }
}

// 2. Create i18n instance with options
const i18n = createI18n({
    legacy: false,
    locale: navigator.language === 'es-ES' ? 'es' : 'en', // set locale
    fallbackLocale: navigator.language === 'es-ES' ? 'es' : 'en', // set fallback locale
    messages: {
        en,
        es
    }, // set locale messages
    datetimeFormats,
    numberFormats
    // If you need to specify other options, you can set other options
    // ...
});

const app = createApp(App);

app.use(store)
    .use(router)
    .use(PrimeVue)
    .use(i18n)
    .use(VueGtag, {
        bootstrap: false
    }, router);

app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Dropdown', Dropdown);
app.component('Dialog', Dialog);
app.component('Toast', Toast);
app.component('ProgressSpinner', ProgressSpinner);
app.component('BlockUI', BlockUI);
app.component('SelectButton', SelectButton);
app.component('Paginator', Paginator);
app.component('Menu', Menu);
app.component('Button', Button);
app.component('TieredMenu', TieredMenu);
app.component('InputSwitch', InputSwitch);
app.component('InputOtp', InputOtp);
app.directive('Tooltip', Tooltip);


app.use(ToastService);


app.mount("#app");
