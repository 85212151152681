export enum RouterPaths {
    Plants = 'Plants',
    Login = 'Login',
    NewSizing = 'NewSizing',
    SizingReport = 'SizingReport',
    Sizings = 'Sizings',
    NewPlant = 'NewPlant',
    General = 'General',
    Inverters = 'Inverters',
    PVModules = 'PVModules',
    RegisterUser = 'RegisterUser',
    RecoverPwd = 'RecoverPwd',
    UserSettings = 'UserSettings',
    UserSecurity = 'UserSecurity'
}
