<template>
  <div class="menu navbar">
    <div class="navbar__wrapper">
      <img class="logo" src="../../assets/img/IS_Planner.svg" @click="goRootPage"/>
      <div v-if="!isRouteLoginRegisterRecoveryUserUndefined && isValidPath" class="navbar__menu">
        <button :class="{'navbar__menu--active': currentRoute === RouterPaths.Plants || currentRoute === RouterPaths.Sizings
        || currentRoute === RouterPaths.NewSizing || currentRoute === RouterPaths.SizingReport || currentRoute === RouterPaths.NewPlant}"
                @click="goToPath('/general/plants')">
          {{ $t('menu.plants') }}
        </button>
        <button :class="{'navbar__menu--active': currentRoute === RouterPaths.Inverters}"
                @click="goToInverters('/general/inverters')">
          {{ $t('menu.inverters') }}
        </button>
        <button :class="{'navbar__menu--active': currentRoute === RouterPaths.PVModules}"
                @click="goToPath('/general/pvmodules')">
          {{ $t('menu.pvmodel') }}
        </button>
      </div>

      <div class="navbar__user">
        <!--        <div>-->
        <!--          <button @click="setLanguage('en')">EN</button>-->
        <!--          <button @click="setLanguage('es')">ES</button>-->
        <!--        </div>-->

        <div class="navbar__language">
          <i class="pi pi-globe"/>
          <!--          <select v-model="language">-->
          <!--            <option>en</option>-->
          <!--            <option>es</option>-->
          <!--          </select>-->
          <dropdownui v-if="languages.length > 0" :selected="languagepicked"
                      :values="languages"
                      @value-selected="setLanguage" :styleClass="'pvmodulemanufacturer__dropdown'"></dropdownui>
        </div>

        <div v-if="isUserLogged">
          <User></User>
          <!--          <button @click="goToPath('/')" v-tooltip.bottom="logoutmsg">-->
          <!--            <i class="pi pi-sign-out"></i>-->
          <!--          </button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, Ref, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import User from "@/components/General/User.vue";
import {useStore} from "vuex";
import GeneralServices from "@/services/GeneralServices";
import {RouterPaths} from "@/types/RouterData";
import {useI18n} from "vue-i18n";
import Dropdownui from "@/components/UI/dropdownUI.vue";
import {DataList} from "@/types/DataList";
import {PVModuleManufacturer} from "@/types/PVModule";

export default defineComponent({
  name: "NavBar",
  components: {
    User,
    Dropdownui
  },
  setup() {

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const languagepicked = ref();

    const languages: Ref<DataList[]> = ref([] as DataList[]);

    const {t, locale} = useI18n({useScope: 'global'});

    // const logoutmsg = ref(t('general.logout'));

    const User = computed(() => {
      return store.getters.getUser;
    });

    const HasInverterFamilySelected = computed(() => {
      return store.getters.getHasInverterFamilySelected;
    });

    const isUserLogged = computed(() => {
      return User.value.id && User.value.id !== 0;
    });

    const currentRoute = computed(() => {
      return route.name;
    });

    const currentPath = computed(() => {
      return route.path;
    });

    const isValidPath = computed(() => {
      const paths = router.getRoutes().map(r => r.path.toLowerCase());
      return paths.includes(currentPath.value.toLowerCase());
    });

    const isRouteLoginRegisterRecoveryUserUndefined = computed(() => {
      return currentRoute.value === RouterPaths.Login
          || currentRoute.value === RouterPaths.RegisterUser
          || currentRoute.value === RouterPaths.RecoverPwd
          || currentRoute.value === undefined;
    });

    const setLanguages = () => {
      languages.value.push({
        label: "Español",
        value: "es"
      });
      languages.value.push({
        label: "English",
        value: "en"
      });
      languagepicked.value = navigator.language === "es-ES" ? "es" : "en";
    };

    const setLanguage = (language: string) => {
      // console.log('cambiar idioma: ' + language);
      locale.value = language;
    };

    const resetNavigationPlantAndSizingAppState = () => {
      GeneralServices.resetNavigationPlantAndSizingAppState(store);
    };

    const resetCompleteSizingAppState = () => {
      GeneralServices.resetCompleteSizingAppState(store);
    };

    const goToInverters = (path: string) => {
      if (path === currentPath.value && HasInverterFamilySelected.value) {
        store.commit("resetHasInverterFamilySelected");
      } else {
        router.push({path: path});
      }
    };

    const goToPath = (path: string) => {
      if (path !== currentPath.value) {
        path === "/" ? resetCompleteSizingAppState() : resetNavigationPlantAndSizingAppState();
        router.push({path: path});
      }
    };

    const goRootPage = () => {
      const path = isRouteLoginRegisterRecoveryUserUndefined.value ? "/" : "/general/plants";
      router.push({path: path});
    };

    // watch(languagepicked, () => {
    //   setLanguage(languagepicked.value);
    // });

    onMounted(setLanguages);

    return {
      isRouteLoginRegisterRecoveryUserUndefined,
      isValidPath,
      isUserLogged,
      currentRoute,
      goToPath,
      goToInverters,
      goRootPage,
      setLanguage,
      RouterPaths,
      User,
      // logoutmsg,
      languagepicked,
      languages
    }
  }
});
</script>

<style scoped>
.navbar {
  background-color: var(--navbar-color);
  width: 100%;
  color: white;
  border-bottom: 0.05rem solid var(--ingeteam-color);
  /*position: fixed;*/
  /*z-index: 1;*/
}

.navbar button {
  color: white;
}

.navbar__wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
}

.logo {
  height: 35%;
  cursor: pointer;
}

.navbar__menu {
  display: flex;
  gap: 1rem;
  margin-left: 5rem;
}

.navbar__user {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}

.navbar__menu--active {
  font-weight: bold;
}

.navbar__language {
  display: flex;
  gap: 1rem;
  align-items: center;
}

</style>
