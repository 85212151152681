export enum LowVoltagePlantSizingWizardSteps {
    // Plantdata,
    PlantSizingName,
    Analysisconditions,
    Inverter,
    PVModule,
    PVGenerator,
    InvertersNumber,
    CalculateAndSave
}

export enum MediumVoltageSizingWizardSteps {
    // Plantdata,
    PlantSizingName,
    ReactiveRequerimentsPOI,
    TransformersWiring,
    Analysisconditions,
    Inverter,
    PVModule,
    PVGenerator,
    InvertersNumber,
    CalculateAndSave
}
