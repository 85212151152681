import http from "@/http-common";
import {User, UserEmail, UserEmailPassword, UserId} from "@/types/User";
import APIConfigService from "@/services/APIConfigService";

class APIDataServices {

    getGeneralVariables(): Promise<any> {
        return http.get(`/${APIConfigService.getUrlToISP()}/general/variables`);
        // this.isDebugEnvironment() ? Promise.resolve({data: variables}) :
    }

    getPVModulesByManufacturerAndUser(manufacturerid: number, userid: number): Promise<any> {
        return http.get(`/${APIConfigService.getUrlToISP()}/pvmodule/user/${userid}/manufacturer/${manufacturerid}`);
    }

    getPVModulesManufacturers(): Promise<any> {
        return http.get(`/${APIConfigService.getUrlToISP()}/pvmodule/manufacturers`);
        // this.isDebugEnvironment() ? Promise.resolve({data: pvmanufacturers}) :
    }

    getPVModuleType(): Promise<any> {
        return http.get(`/${APIConfigService.getUrlToISP()}/pvmodule/pvmoduletype`);
    }

    getInvertersByFamily(familyid: number): Promise<any> {
        // const data = familyid === 1 ? inverter1 : familyid === 2 ? inverter2 : null;
        return http.get(`/${APIConfigService.getUrlToISP()}/inverter/${familyid}`);
        // this.isDebugEnvironment() ? Promise.resolve({data: data}) :
    }

    getInvertersFamilies(): Promise<any> {
        return http.get(`/${APIConfigService.getUrlToISP()}/inverter/families`);
        // this.isDebugEnvironment() ? Promise.resolve({data: families}) :
    }

    // getInvertersMediumVoltage(): Promise<any> {
    //     return http.get(`/${APIConfigService.getAPIURLISP()}/inverter/mediumvoltage`);
    // }

    plantSizingLowVoltageUpdate(sizingid: number, data: any): Promise<any> {
        return http.put(`/${APIConfigService.getUrlToISP()}/plantsizinglowvoltage/sizing/${sizingid}`, data);
    }

    plantSizingLowVoltageCalculateNew(data: any): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISP()}/plantsizinglowvoltage`, data);
    }

    plantSizingLowVoltageCalculateExist(data: any): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISP()}/plantsizinglowvoltage/plantsizing`, data);
        // this.isDebugEnvironment() ? Promise.resolve({data: plantsizing}) :
    }

    plantSizingMediumVoltageCalculateExist(data: any): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISP()}/plantsizingmediumvoltage/plantsizing`, data);
    }

    plantSizingLowVoltageDelete(plantid: number, sizingid: number): Promise<any> {
        return http.delete(`/${APIConfigService.getUrlToISP()}/plantsizinglowvoltage/plant/${plantid}/sizing/${sizingid}`);
    }

    plantSizingLowVoltageClone(plantid: number, sizingid: number): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISP()}/plantsizinglowvoltage/clone/plant/${plantid}/sizing/${sizingid}`);
    }

    plantSizingLowVoltageEdit(data: any): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISP()}/plantsizinglowvoltage/edit`, data);
    }

    plantSizingLowVoltageReport(language: string, data: any): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISP()}/plantsizinglowvoltage/report/${language}`,
            data,
            {
                responseType: 'arraybuffer',
                headers: {
                    Accept: 'application/pdf',
                },
            });
    }

    plantSizingMediumVoltageDelete(plantid: number, sizingid: number): Promise<any> {
        return http.delete(`/${APIConfigService.getUrlToISP()}/plantsizingmediumvoltage/plant/${plantid}/sizing/${sizingid}`);
    }

    mediumvoltagesizingcalculate(data: any): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISP()}/plantsizingmediumvoltage`, data);
    }

    getCountryGridCodeTypes(): Promise<any> {
        return http.get(`/${APIConfigService.getUrlToISP()}/countrygridcode`);
    }

    getWiringLostTypes(): Promise<any> {
        return http.get(`/${APIConfigService.getUrlToISP()}/wiringlosttype`);
    }

    getPlantsByUser(userid: number): Promise<any> {
        return http.get(`/${APIConfigService.getUrlToISP()}/user/${userid}/plants`);
        // this.isDebugEnvironment() ? Promise.resolve({data: plants}) :
    }

    // getUserToken(userdata: UserSimple): Promise<any> {
    //     return http.post(`/${APIConfigService.getAPIURLISP()}/user/token`, userdata);
    //     // this.isDebugEnvironment() ? Promise.resolve({data: token}) :
    // }

    // getUserTokenValidate(userdata: UserEmailPassword): Promise<any> {
    //     return http.post(`/${APIConfigService.getAPIURLISP()}/user/tokenvalidate`, userdata);
    // }
    getUserTokenISP(userdata: UserId): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISP()}/user/token`, userdata);
    }

    getUserTokenISM(userdata: UserEmailPassword): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISM()}/users/token`, userdata);
    }

    getValidateUserISM(userdata: UserEmailPassword): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISM()}/users/validate`, userdata);
    }

    // validateUserData(userdata: UserSimple): Promise<any> {
    //     return http.post(`/${APIConfigService.getAPIURLISP()}/user/validate`, userdata);
    //     // this.isDebugEnvironment() ? Promise.resolve({data: validate}) :
    // }

    recoveryUserPwd(userEmail: UserEmail): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISM()}/users/recovery`, userEmail);
    }

    registerUser(user: User): Promise<any> {
        // return http.post(`/${APIConfigService.getUrlToISP()}/user/registeruser`, user);
        return http.post(`/${APIConfigService.getUrlToISM()}/internal/users/register`, user);
    }

    // getPlantById(plantid: number): Promise<any> {
    //     return http.get(`/${APIConfigService.getAPIURLISP()}/plant/${plantid}`);
    // }

    deletePlantById(plantid: number): Promise<any> {
        return http.delete(`/${APIConfigService.getUrlToISP()}/plant/${plantid}`);
    }

    getSizingsByPlantId(plantid: number): Promise<any> {
        return http.get(`/${APIConfigService.getUrlToISP()}/plant/${plantid}/sizings`);
        // this.isDebugEnvironment() ? Promise.resolve({data: sizings}) :
    }

    registerplant(data: any): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISP()}/plant`, data);
    }

    registerpvmodule(data: any): Promise<any> {
        return http.post(`/${APIConfigService.getUrlToISP()}/pvmodule`, data);
    }

    updatepvmodule(data: any, userid: number, pvmoduleid: number): Promise<any> {
        return http.put(`/${APIConfigService.getUrlToISP()}/pvmodule/user/${userid}/pvmodule/${pvmoduleid}`, data);
    }

    deletePVModule(userid: number, pvmoduleid: number): Promise<any> {
        return http.delete(`/${APIConfigService.getUrlToISP()}/pvmodule/user/${userid}/pvmodule/${pvmoduleid}`);
    }

    // createReport(): Promise<any> {
    //     return http.get(`/${APIConfigService.getAPIURLISP()}/report/create`,
    //         {
    //             responseType: 'arraybuffer',
    //             headers: {
    //                 Accept: 'application/pdf',
    //             },
    //         });
    // }
}

export default new APIDataServices();
