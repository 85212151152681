<template>
  <NavBar></NavBar>
  <div class="body__views">
    <router-view/>
  </div>
  <FooterBar></FooterBar>
</template>

<style >
/* Order */
@layer reset, primevue;
</style>
<script>

import NavBar from "@/components/General/NavBar";
import FooterBar from "@/components/General/FooterBar";
import {setLocale} from "yup";

export default {
  components: {
    FooterBar,
    NavBar
  },
  setup() {
    setLocale({
      string: {
        required: 'fieldRequired',
        email: 'validEmail',
        notType: 'fieldRequired',
        max: 'maxLength_${max}',
        min: 'minLength_${min}'
      },
      number: {
        required: 'fieldRequired',
        max: 'max_${max}',
        min: 'min_${min}',
        notType: 'fieldRequired'
      },
      mixed: {
        required: 'fieldRequired',
        max: 'max_${max}',
        min: 'min_${min}',
        notType: 'fieldRequired'
      }
    });
  }
}
</script>
